<template>
  <div>
    <s-search :queryItems="queryItems" @fetchData="fetchStores"></s-search>

    <div class="table-button">
      <a-button
        type="plain"
        icon="cloud-upload"
        @click="handleStore"
        style="float: left"
        v-if="isShowBtns.indexOf('DeviceStock-/api/device/import') > -1"
      >
        <!-- 导入列表 -->
        导入设备账号表格
      </a-button>
      <a-button
        type="plain"
        icon="cloud-upload"
        @click="handleStoreSend"
        style="float: left"
        v-if="
          isShowBtns.indexOf('DeviceStock-/api/device/distributionimport') > -1
        "
      >
        导入设备发货分配表格
      </a-button>
      <a-button
        type="plain"
        icon="cluster"
        @click="handleDestribute"
        v-if="isShowBtns.indexOf('DeviceStock-/api/device/distribution') > -1"
        >设备分配</a-button
      >
      <columns-set
        tableName="DeviceStock"
        :columns="columns"
        @changeColumns="changeColumns"
      ></columns-set>
    </div>

    <s-table
      ref="table"
      size="default"
      rowKey="d_id"
      :rowSelection="rowSelection"
      :columns="tableColumns"
      :data="loadData"
      :showPagination="true"
      :scroll="{ x: 1100, y: 'calc(100vh - 180px )' }"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ (pageNo - 1) * pageSize + index + 1 }}
      </span>
      <span slot="d_status" slot-scope="text">
        <span v-if="text === 1 ? true : false" style="color: green">●</span>
        <span v-else style="color: red">●</span>

        {{ text | FDeviceStatus }}
      </span>
      <span slot="d_stock_time" slot-scope="text">
        {{ $Format(text, "date") }}
      </span>
      <span slot="send_date" slot-scope="text">
        {{ $Format(text, "date") }}
      </span>
      <span slot="sign_date" slot-scope="text">
        {{ $Format(text, "date") }}
      </span>
      <span slot="d_distribution_time" slot-scope="text">
        {{ $Format(text, "date") }}
      </span>
      <span slot="iccid" slot-scope="text">
        <a-tooltip placement="topLeft">
          <template slot="title">
            <span>{{ text }}</span>
          </template>
          <span>{{ text }}</span>
        </a-tooltip>
      </span>
    </s-table>

    <a-drawer
      title="设备分配"
      placement="right"
      :width="750"
      :visible="visible"
      :destroyOnClose="true"
      @close="onClose"
      class="site-modal"
    >
      <destribute
        :record="record"
        :distribution="distribution"
        @onClose="onClose"
        ref="Destribute"
      ></destribute>
    </a-drawer>

    <a-modal
      class="tips-modal"
      title="上传文件"
      okText="确定"
      cancelText="取消"
      :visible="visibleImport"
      :confirm-loading="confirmImportLoading"
      @ok="importOk"
      @cancel="importCancel"
    >
      <file-upload :visibleImport="visibleImport"></file-upload>
    </a-modal>
    <a-modal
      class="tips-modal"
      title="导入设备发货分配表"
      okText="确定"
      cancelText="取消"
      :visible="visibleImportSend"
      :confirm-loading="confirmImportLoadingSend"
      @ok="importOkSend"
      @cancel="importCancelSend"
    >
      <FileUploadsend />
    </a-modal>
  </div>
</template>

<script>
import { STable, SSearch, ColumnsSet } from "@/components";
import { GetStock, StoreDevice } from "@/api/device";
import Destribute from "./Destribute.vue";
import FileUpload from "./FileUpload.vue";
import FileUploadsend from "./FileUploadsend.vue";
// 搜索条件
const queryItems = [
  { label: "IMEI", value: "d_imei", type: "input" },
  {
    label: "设备状态",
    value: "d_status",
    type: "select",
    selectOption: "DeviceStatus",
    defaultValue: "0",
  },
  {
    label: "设备所属组织",
    value: "BcCs_id",
    type: "TreeSelect",
    range: ["bc_id", "cs_id"],
    selectOption: "CompanystoreList",
    defaultValue: ["", ""],
  },
  {
    label: "设备型号",
    value: "d_model",
    type: "input",
    // selectOption: "DeviceXinghao",
  },
  {
    label: "设备版本号",
    value: "d_version",
    type: "input",
    // type: "select",
    // selectOption: "DeviceBanbenhao",
  },

  {
    label: "设备分配日期",
    value: "distribution_time",
    type: "dateRange",
    range: ["distribution_time_start", "distribution_time_end"],
    defaultValue: [],
  },

  {
    label: "设备入库日期",
    value: "stock_time",
    type: "dateRange",
    range: ["stock_time_start", "stock_time_end"],
    defaultValue: [],
  },
  // {
  //   label: "设备故障报警",
  //   // value: "d_status",
  //   type: "select",
  //   // selectOption: "DeviceStatus",
  // },
];
const columns = [
  {
    title: "序号",
    width: "70px",
    scopedSlots: { customRender: "serial" },
    dataIndex: "serial",
    align: "center",
  },
  {
    title: "设备账号",
    width: "130px",
    dataIndex: "d_htx_id",
    align: "center",
  },
  {
    title: "设备所属组织",
    width: "145px",
    dataIndex: "bc_org",
    align: "center",
  },
  {
    title: "IMEI号",
    width: "160px",
    dataIndex: "d_imei",
    align: "center",
  },
  {
    title: "设备状态",
    width: "90px",
    dataIndex: "d_status",
    scopedSlots: { customRender: "d_status" },
    align: "center",
  },
  {
    title: "设备型号",
    width: "90px",
    dataIndex: "d_model",
    scopedSlots: { customRender: "d_model" },
    align: "center",
  },
  {
    title: "设备版本号",
    width: "110px",
    dataIndex: "d_version",
    scopedSlots: { customRender: "d_version" },
    align: "center",
  },
  {
    title: "设备入库日期",
    width: "120px",
    dataIndex: "d_stock_time",
    scopedSlots: { customRender: "d_stock_time" },
    align: "center",
  },
  {
    title: "设备分配日期",
    width: "120px",
    dataIndex: "d_distribution_time",
    scopedSlots: { customRender: "d_distribution_time" },
    align: "center",
  },
  {
    title: "设备绑定人",
    width: "110px",
    dataIndex: "w_name",
    align: "center",
  },
  // {
  //   title: "设备故障报警",
  //   width: "100px",
  //   // dataIndex: "w_name",
  //   align: "center",
  // },
  {
    title: "发货日期",
    width: "120px",
    dataIndex: "send_date",
    scopedSlots: { customRender: "send_date" },
    align: "center",
  },
  {
    title: "签收日期",
    width: "120px",
    dataIndex: "sign_date",
    scopedSlots: { customRender: "sign_date" },
    align: "center",
  },
  {
    title: "设备ICCID",
    width: "120px",
    dataIndex: "iccid",
    scopedSlots: { customRender: "iccid" },
    align: "center",
    ellipsis: true,
  },
  {
    title: "备注",
    width: "100px",
    dataIndex: "notes",
    align: "center",
  },
];
const orderParam = [
  "stock_time_start",
  "stock_time_end",
  "distribution_time_start",
  "distribution_time_end",
  "d_imei",
  "d_version",
  "d_model",
  "d_status",
  "bc_id",
  "cs_id",
  "pageNo",
  "pageSize",
];

export default {
  name: "DeviceStock",
  components: {
    STable,
    SSearch,
    Destribute,
    FileUpload,
    ColumnsSet,
    FileUploadsend,
  },
  data() {
    this.queryItems = queryItems;
    this.columns = columns;
    return {
      tableColumns: [],
      visible: false,
      confirmLoading: false,
      visibleImport: false,
      visibleImportSend: false,
      confirmImportLoading: false,
      confirmImportLoadingSend: false,
      // 查询参数
      queryParam: {
        d_status: "0",
      },
      selectedRowKeys: [],
      selectedRows: [],
      record: {},
      destribute: {
        w_id: "",
      },
      exportDistributeLoading: false,
      distribution: "1",
      //当前页数，当前条数
      pageNo: 1,
      pageSize: 10,
      isShowBtns: [],
    };
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange,
      };
    },
  },
  created() {
    this.tableColumns = columns;
    this.changeColumns();
    // this.queryItems[4].label = `分配日期`;
  },
  mounted() {
    this.isShowBtns = sessionStorage.getItem("isShowBtns").split(",");
  },
  methods: {
    // 自定义表头列
    changeColumns() {
      let ColumnsSet = JSON.parse(
        localStorage.getItem("DeviceStockColumnsSet")
      );
      let value = ColumnsSet ? ColumnsSet : [];
      let selectColumns = columns.filter((el) => value.includes(el.dataIndex));
      this.tableColumns = selectColumns.length ? selectColumns : columns;
    },
    // 加载数据方法 必须为 Promise 对象
    loadData(parameter) {
      this.pageNo = parameter.pageNo;
      this.pageSize = parameter.pageSize;
      const param = Object.assign({}, parameter, this.queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        // ws_status: "",
        // d_status: "",
        // ws_bind_status: "",
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetStock(params).then((res) => {
        res.data.pageNo = res.data.page;
        res.data.totalCount = res.data.sum;
        // console.log(res.data);
        return res.data;
      });
    },
    fetchStores(value) {
      this.queryParam = value;
      this.$refs.table.refresh(true);
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    // 设备分配
    handleDestribute() {
      this.record = this.selectedRows;
      this.visible = true;
    },
    onClose(refresh) {
      if (refresh) {
        this.fetchStores();
      }
      this.visible = false;
    },
    // 导入
    handleStore() {
      this.visibleImport = true;
    },
    importOk() {
      this.confirmImportLoading = true;
      const params = {
        ws_ids: "",
      };
      StoreDevice(params).then((res) => {
        this.visibleImport = false;
        this.confirmImportLoading = false;
        // 刷新表格
        this.$refs.table.refresh();
        console.log(res);
      });
    },
    importCancel() {
      this.visibleImport = false;
    },
    // 导入设备发货表格
    handleStoreSend() {
      this.visibleImportSend = true;
    },
    importOkSend() {
      this.confirmImportLoadingSend = true;
      const params = {
        ws_ids: "",
      };
      StoreDevice(params)
        .then((res) => {
          this.visibleImport = false;
          this.confirmImportLoading = false;
          // 刷新表格
          this.$refs.table.refresh();
          console.log(res);
        })
        .then(() => {
          this.visibleImportSend = false;
        });
    },
    importCancelSend() {
      this.visibleImportSend = false;
    },
  },
};
</script>
<style scoped lang="less">
// 表头居中显示
/deep/.ant-table-thead > tr > th {
  text-align: center;
}
</style>
