<template>
  <a-upload
    name="devicefile"
    list-type="picture-card"
    class="avatar-uploader"
    :show-upload-list="false"
    :action="fileUrl"
    :before-upload="beforeUpload"
    @change="handleChange"
  >
    <a-icon :type="loading ? 'loading' : 'plus'" />
    <div class="ant-upload-text">
      {{ textData }}
    </div>
  </a-upload>
</template>

<script>
import { urlConfig } from "@/config/config";

export default {
  name: "FileUploadsend",
  data() {
    return {
      loading: false,
      imageUrl: "",
      textData: "上传",
      fileUrl: urlConfig.baseUrl + "/dsmcore/api/device/distributionimport",
    };
  },
  methods: {
    handleChange(info) {
      if (info.file.status === "uploading") {
        this.textData = "上传中";
        this.loading = true;
        return;
      }
      if (info.file.status === "done") {
        this.textData = "上传";
        this.loading = false;
        // Get this url from response in real world.
        // getBase64(info.file.originFileObj, imageUrl => {
        //   this.imageUrl = imageUrl;
        //   this.loading = false;
        // });
      }
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "application/vnd.ms-excel" ||
        file.type === "application/vnd.ms-office" ||
        file.type === "application/octet-stream" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isJpgOrPng) {
        this.$message.error("只能上传Excel文件!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("文件最大2MB!");
      }
      return isJpgOrPng && isLt2M;
    },
  },
};
</script>
<style>
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
