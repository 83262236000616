<template>
  <div class="flex">
    <a-upload
      name="devicefile"
      list-type="picture-card"
      class="avatar-uploader"
      :show-upload-list="false"
      :action="fileUrl"
      :before-upload="beforeUpload"
      @change="handleChange"
    >
      <!-- <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
    <div v-else>
      <a-icon :type="loading ? 'loading' : 'plus'" />
      <div class="ant-upload-text">
        {{textData}}
      </div>
    </div> -->
      <a-icon :type="loading ? 'loading' : 'plus'" />
      <div class="ant-upload-text">
        {{ textData }}
      </div>
    </a-upload>
    <div class="result">
      <div v-if="resultData.length > 0">
        <div v-for="(item, index) in resultData" :key="index">
          <div>设备imei号： {{ item.d_imei }}；</div>
          <div>上传状态： {{ item.result }}</div>
        </div>
      </div>
      <div class="success" v-show="success">上传成功</div>
    </div>
  </div>
</template>

<script>
import { urlConfig } from "@/config/config";
// function getBase64(img, callback) {
//   const reader = new FileReader();
//   reader.addEventListener('load', () => callback(reader.result));
//   reader.readAsDataURL(img);
// }
export default {
  name: "FileUpload",
  props: {
    visibleImport: {
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      imageUrl: "",
      textData: "上传",
      fileUrl: urlConfig.baseUrl + "/dsmcore/api/device/import",
      resultData: [],
      success: false,
    };
  },
  created() {},
  mounted() {
    this.resultData = [];
  },
  watch: {
    visibleImport(newValue) {
      this.resultData = [];
      this.success = false;
    },
  },
  methods: {
    handleChange(info) {
      console.log(info.fileList);
      let fileList_length = info.fileList.length - 1;
      this.resultData = info.fileList[fileList_length].response.data;
      console.log(this.resultData);
      let that = this;
      if (this.resultData.length > 0) {
      } else {
        // that.$message.success("上传成功");
        this.success = true;
      }
      if (info.file.status === "uploading") {
        this.textData = "上传中";
        this.loading = true;
        return;
      }
      if (info.file.status === "done") {
        this.textData = "上传";
        this.loading = false;
        // Get this url from response in real world.
        // getBase64(info.file.originFileObj, imageUrl => {
        //   this.imageUrl = imageUrl;
        //   this.loading = false;
        // });
      }
    },
    beforeUpload(file) {
      this.resultData = [];
      const isJpgOrPng =
        file.type === "application/vnd.ms-excel" ||
        file.type === "application/vnd.ms-office" ||
        file.type === "application/octet-stream" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isJpgOrPng) {
        this.$message.error("只能上传Excel文件!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("文件最大2MB!");
      }
      return isJpgOrPng && isLt2M;
    },
  },
};
</script>
<style>
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.flex {
  display: flex;
}
.result {
  width: 700px;
  /* background-color: pink; */
}
.success {
  color: green;
}
</style>
